import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { FlatList, SafeAreaView, TextInput, TouchableHighlight, Dimensions, ScrollView, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';
import HTML from "react-native-render-html";

import AsyncStorage from '@react-native-async-storage/async-storage';

//import Icon from 'react-native-vector-icons/MaterialIcons';
import { MaterialCommunityIcons, Ionicons, Octicons, FontAwesome5  } from '@expo/vector-icons';
//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Navigation, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import DB from '../data/db.js' ;

import * as SQLite from 'expo-sqlite';

import styles from '../styles/common.js' ;

const dimensions = Dimensions.get('window');

function naturalSort (a, b) {
    var re = /(^([+\-]?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?(?=\D|\s|$))|^0x[\da-fA-F]+$|\d+)/g,
    sre = /^\s+|\s+$/g,   // trim pre-post whitespace
    snre = /\s+/g,        // normalize all whitespace to single ' ' character
    dre = /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,
    i = function(s) {
        return (naturalSort.insensitive && ('' + s).toLowerCase() || '' + s).replace(sre, '');
    },
    // convert all to strings strip whitespace
    x = i(a),
    y = i(b),
    // chunk/tokenize
    xN = x.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
    yN = y.replace(re, '\0$1\0').replace(/\0$/,'').replace(/^\0/,'').split('\0'),
    // numeric, hex or date detection
    xD = parseInt(x.match(hre), 16) || (xN.length !== 1 && Date.parse(x)),
    yD = parseInt(y.match(hre), 16) || xD && y.match(dre) && Date.parse(y) || null,
    normChunk = function(s, l) {
        // normalize spaces; find floats not starting with '0', string or 0 if not defined (Clint Priest)
        return (!s.match(ore) || l == 1) && parseFloat(s) || s.replace(snre, ' ').replace(sre, '') || 0;
    },
    oFxNcL, oFyNcL;
    // first try and sort Hex codes or Dates
    if (yD) {
        if (xD < yD) { return -1; }
        else if (xD > yD) { return 1; }
    }
    // natural sorting through split numeric strings and default strings
    for(var cLoc = 0, xNl = xN.length, yNl = yN.length, numS = Math.max(xNl, yNl); cLoc < numS; cLoc++) {
        oFxNcL = normChunk(xN[cLoc] || '', xNl);
        oFyNcL = normChunk(yN[cLoc] || '', yNl);
        // handle numeric vs string comparison - number < string - (Kyle Adams)
        if (isNaN(oFxNcL) !== isNaN(oFyNcL)) {
            return isNaN(oFxNcL) ? 1 : -1;
        }
        // if unicode use locale comparison
        if (/[^\x00-\x80]/.test(oFxNcL + oFyNcL) && oFxNcL.localeCompare) {
            var comp = oFxNcL.localeCompare(oFyNcL);
            return comp / Math.abs(comp);
        }
        if (oFxNcL < oFyNcL) { return -1; }
        else if (oFxNcL > oFyNcL) { return 1; }
    }
}
        
function ShopLine(props) {
    let s = props.g ;
    //console.log ('ShopLine', props)
    if (s.subs) {
        s.fromPrice = Math.abs(s.final_price) ;
        let subs = [] ;
        for (let i in s.subs) {
            let b = s.subs[i] ;
            
            let titles = [] ;
            let pers = false ;
            
            if (b.item_id == 54) { // нагревательный мат
                let params = [] ;
                if (b.gv.gv_478) params.push(<Text style={{marginRight:6,minWidth:47}} key="gv_478">{b.gv['gv_478']} м2</Text>)
                
                if (b.gv.gv_249) {
                    if (b.gv['gv_249']>1000) {
                        params.push(<Text style={{marginRight:6,minWidth:50}} key="gv_249">{Math.round(b.gv['gv_249']/100)/10} кВт</Text>)
                    } else {
                        params.push(<Text style={{marginRight:6,minWidth:50}} key="gv_249">{b.gv['gv_249']} Вт</Text>)
                    }
                }                
                if (b.filter_2) params.push(<Text style={{marginRight:6,minWidth:80}} key="filter_2">{b['filter_2']}</Text>)
                titles.push(<View style={{flexDirection:'row'}} key={'sublines_' + b.id}>{params}</View>) ;
                pers = true ;
            } 
            if (!pers) {
                titles.push(<Text key={'title_' + b.id} onPress={() => openGood(b.id)} style={{maxHeight:20,overflow:'hidden',fontSize:12}}>{b.short_title || b.title}</Text>) ;
            }

            subs.push(<View key={'shop_line_' + b.id} style={{overflow:'hidden',paddingBottom:10,paddingTop:10,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
                            <TouchableHighlight onPress={()=>{ openGood(b.id) }} style={{width:dimensions.width-140,paddingLeft:10}}>
                                <View>
                                    {titles}
                                </View>
                            </TouchableHighlight>
                            <View style={{justifyContent: 'center' }}>
                                <Text style={{paddingRight:5,textAlign:'right',width:80,fontSize:14,fontWeight:'bold'}}>{b.final_price} р.</Text>
                            </View>
                            <TouchableHighlight onPress={()=>{}} style={{maxHeight:32,alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, marginTop:-6,marginBottom:-5,width:50}}>
                                <Ionicons name="md-cart" size={20} color="white"/>
                            </TouchableHighlight>
                        </View>) ;
        }
        return (
            <View>
                <View key={'sub_'+s.id}  style={{overflow:'hidden',paddingTop:10,paddingLeft:10,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
                    <View style={{alignContent:'center',width:60,height:60}}>
                        <Image
                                onPress={() => openGood(s.id)}
                                style={{marginRight:20,width:60,height:60,resizeMode:'contain'}}
                                source={{uri: 'https://www.sin-el.ru/p/800x/' + s.id + '_1.jpg?fromExpo=true'}}
                            />
                    </View>
                    <View style={{width:dimensions.width-70,paddingLeft:10, paddingRight:10}}>
                        <Text onPress={() => openGood(s.id)} style={{maxHeight:50,overflow:'hidden',fontSize:16, paddingBottom:5}}>{s.short_title||s.title}</Text>
                        <HTML html={s.post_title} contentWidth={'100%'} />
                        <View>
                            <Text style={{paddingRight:5,textAlign:'right',fontSize:16, paddingBottom:5}}>от {s.fromPrice} р.</Text>
                        </View>
                    </View>
                </View>
                <View style={{width:'100%'}}>{true?subs:{}}</View>
            </View>
        );
    }
    
    return (
      <View key={'sub_'+s.id}  style={{overflow:'hidden',paddingTop:10,paddingLeft:10,height:80,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
                        <View style={{alignContent:'center',width:60,height:60}}>
                            <Image
                                style={{marginRight:20,width:60,height:60,resizeMode:'contain'}}
                                source={{uri: 'https://www.sin-el.ru/p/800x/' + s.id + '_1.jpg?fromExpo=true'}}
                            />
                        </View>
                        <View style={{width:dimensions.width-210,paddingLeft:10}}>
                            <Text onPress={() => openGood(s.id)} style={{maxHeight:50,overflow:'hidden',fontSize:12}}>{s.short_title||s.title}</Text>
                            <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}>{s.vendor} {s.articul}</Text>
                        </View>
                        <View style={{justifyContent: 'center', height:40}}>
                        <Text style={{paddingRight:10,textAlign:'right',width:80,fontSize:16}}>{s.final_price} р.</Text>
                        </View>
                        <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:50,height:40}}>
                            <Ionicons name="md-cart" size={20} color="white"/>
                        </TouchableHighlight>
                        </View>
  );
}

export default class GoodsList extends React.Component {
    
    state = {
        loading: true,
        g: {},
        s: false,
        activeFilters: {},
        //f: {filter_3 : '125A'},
        //f: {filter_3 : '10A', 'filter_2' : '1 фаза (1P)'},
        filtersOpen: true, //false,
        sortOpen: false,
        filterValues : {},
        filterFields : [],
        filteredGoods : [],
        id:0,
    }

    constructor (props) {
        super(props) ;
        if (props.navigation) this.nav = props.navigation ;
        this.state.id = 33054 ;
        if (props.route && props.route.params && props.route.params.id) this.state.id = props.route.params.id ;
        console.log ('GoodsList current', this.state.id) ;        
        
    }

    async componentDidMount() {
        this.reloadData(false) ;
    }
    
    filteredFilters = {}
    filtersValues = {} ;
    hiddenFilters = {}
    subsCount = 0 
    
    getFilteredGoods() { //-- Фильтрация и сортировка товаров
        console.log ('------> getFilteredGoods start')
        let subs = [] ;
        
        if (this.state.activeFilters) {
            for (let k in this.state.activeFilters) {
                if (this.state.s.filterFields.indexOf(k) == -1) delete this.state.activeFilters[k] ;
            }
        }
        
        //console.log ('getFilteredGoods',this.state,this.state.activeFilters) ;
        if (!this.state.g || !this.state.s ) return [] ;
        for (let i in this.state.g) {
            let x = this.state.g[i] ;
            if (!+x.final_price) continue ;
            let skip = false ;
            if (Object.keys(this.state.activeFilters).length) {
                for (let f of this.state.s.filterFields) {
                    //if (!x[f]) continue ;
                    //if (x.articul='GV2P01') debugger ;
                    let v = x[f]||'' ;
                    if (this.state.activeFilters[f]) {
                        if (typeof this.state.activeFilters[f] == 'undefined') continue ;
                        //if (!v) skip = true ;
                        if (typeof this.state.activeFilters[f] == 'string') {
                            if (!v || v.toLowerCase() != this.state.activeFilters[f].toLowerCase()) {
                                skip = true ;
                            }
                        }
                        //console.log (f, this.state.activeFilters[f], v, skip) ;
                    }
                }
            }
            if (!skip) subs.push(x) ;
        }
        //console.log ('subs',subs) ;
        subs.sort(function(a,b) {
            if ((+a.our_qty||0)!=(+b.our_qty||0)) return (+b.our_qty||0) - (+a.our_qty||0) ;
            if ((+a.other_qty||0)!=(+b.other_qty||0)) return (+b.other_qty||0) - (+a.other_qty||0) ;
            if ((+a.orderby||0)!=(+b.orderby||0)) return (+b.orderby||0) - (+a.orderby||0) ;
            if ((+a.orderby_sub||0)!=(+b.orderby_sub||0)) return (+b.orderby_sub||0) - (+a.orderby_sub||0) ;
            return (+a.final_price||0) - (+b.final_price||0) ;
        })
        
        let fl = {} ;
        for (let x of subs) {
            for (let f of this.state.s.filterFields) {
                if (this.state.activeFilters[f]) continue ;
                if (!x[f]) continue ;
                let v = (''+x[f]).toLowerCase() ;
                if (!fl[f]) fl[f] = [] ;
                if (fl[f].indexOf(v)==-1) fl[f].push(v) ;
            }
        }
        this.filteredFilters = fl ;
   //
        console.log ('fl',fl) ;

        this.subsCount = subs.length ;

        if (subs.length>=100) subs = subs.slice(0, 100)
        
        subs.unshift({id:'total', type:'total'}) ;
        subs.unshift({id:'filters', type:'filters'}) ;

        let fs = [] ;
        for (let f of this.state.s.filterFields) {
            //if (f == 'filter_3') console.log ('activeFilters', this.state.activeFilters[f]) ;
        //if (f == 'filter_2') debugger ;
            let usePrev = this.state.s.data[f + '_useprev'] ;
            let usePrevList = this.state.s.data[f + '_useprev_list'] ;
            if (typeof usePrevList == 'string') usePrevList = [ usePrevList ] ;
            
            if (usePrevList && usePrevList.length) {
                let ok = true ;
                for (let fi of usePrevList) {
                    if (!this.state.activeFilters['filter_'+fi]) ok = false ;
                }
                console.log ('usePrev, usePrevList', usePrev, usePrevList, this.state.activeFilters[f], ok) ;    
                if (!ok) continue ;
            }
            
            let fv = [] ;
            if (!this.state.filterValues[f]) continue ;
            let kk = Object.keys(this.state.filterValues[f]) ;
            kk.sort(naturalSort) ;
            
            console.log ('===========', this.state.s.filterMainValues) ;
            
            if (this.state.s.filterMainValues[f]) { // выставили вперед filter_X_main_values
                let MV = [ ... this.state.s.filterMainValues[f] ].reverse() ;
                for (let mv of MV) {
                    mv = mv.toLowerCase() ;
                    let i = kk.indexOf(mv) ;
                    if (i==-1) continue ;
                    kk.splice(i, 1);
                    kk.unshift(mv) ;
                }
            }
//console.log (this.filteredFilters[f]) ;
            if (this.filteredFilters[f]) {
                let absent = [] ;
                for (let v of kk) {
                    if (this.filteredFilters[f].indexOf(v)==-1) absent.push(v) ;
                }
                //console.log (absent) ;
                for (let z of absent) {
                    //debugger ;
                    let i = kk.indexOf(z) ;
                    if (i==-1) continue ;
                    kk.splice(i, 1);
                    //kk.push(z) ;
                }
                this.hiddenFilters = absent ;
                //console.log (f, this.filteredFilters[f], kk) ;
            }

        
        
            //-- Вывод фильтров
            
            let n = 0 ;
            let af = [] ;
            for (let v of kk) {
                n++ ;
                let selected = false ;
                // /if (this.state.activeFilters[f]) 
               // console.log (f, this.state.activeFilters[f],v) ;
                if (this.state.activeFilters[f] == v) selected = true ;
                let cnt = this.state.filterValues[f][v] ;
              //  console.log ('flt_' + f + '_value_'+n,v) 
                fv.push(<Text key={'flt_' + f + '_value_'+n} onPress={()=>{
                    let af = this.state.activeFilters ;
                    console.log ('activeFilters', af) 
                    if (af[f] == v) {
                        delete af[f] ; 
                    } else {
                        af[f] = v ;
                    }
                    this.setState({activeFilters:af}) ;
                    AsyncStorage.setItem('filters_' + this.state.id, JSON.stringify(af)) ;
                }} style={selected ? styles.filterButtonActive : styles.filterButton}>{v} <Text style={{fontSize:10,color:'#888'}}>{cnt}</Text></Text>)
            }
            if (this.state.activeFilters[f]) {
                af.push(<Text key={'active_' + f} style={{paddingTop:3,paddingBottom:3,paddingRight:5,paddingLeft:5,borderRadius:5,backgroundColor:'#888',color:'white'}}>{this.state.activeFilters[f]} <Ionicons name="ios-close" color="white" /></Text>) ;
            }
            fs.push(<Text key={'flt_' + f + '_title'} style={{width:'100%',padding:10}}>{this.state.s[f]} {af.length ? ' ' :''}{af}</Text>) ;
            fs.push(<View key={'flt_' + f + '_values'} style={{width:'100%'}}><ScrollView horizontal>{fv}</ScrollView></View>) ;
        }
        this.filterView = fs ;

        console.log ('------> getFilteredGoods end')
      //  console.log ('gotFilteredGoods',subs) ;
        return subs ;
    }    
    
    ss = {} 
    
    async reloadData (force) { //--
        //console.log ('>> reloadData',force)
        let json = false ;
        if (this.state.id) {
            json = await DB.getSection(this.state.id, force)
            console.log ('json',json) ;
        }
        
        let toSet = {loading:false} ;
        
        json.s.filterMainValues = {} ;
        json.s.filterFields = [] ;
        
        console.log (json.s.data) ;
        let s = json.s ;
        let fo = [], fF = {} ;
        for (let i = 1 ; i <= 10 ; i ++) {
            if (!s['filter_'+i]) continue ;
            if (s.data && s.data['filter_'+i+'_deco']) continue ;
            if (s.data && s.data['filter_'+i+'_mainvalues']) {
                let mv = s.data['filter_'+i+'_mainvalues'].split(/[|,;\n]+/) ;
                json.s.filterMainValues['filter_' + i] = mv ;
            }
            fo.push([i,+s.data['filter_'+i+'_orderby']||0]) 
            fF[i] = 'filter_'+i ;
        }
        fo.sort(function(a,b) {
            return (a[1]||1000) - (b[1]||1000) ;
        })
        json.s.filterFields = [] ;
        for (let x of fo) {
            console.log ('json.s.filterFields',json.s.id, x, fF[x[0]]) ;
            if (json.s.id == 159599 && fF[x[0]] == 'filter_1') continue ; //-- Костыль
            if (json.s.id == 32362 && fF[x[0]] == 'filter_1') continue ; //-- Костыль
            json.s.filterFields.push(fF[x[0]]) ;
        }
        if (json.s.id == 32362) {
            json.s.filter_2 = 'Тип' ;
            json.s.data.filter_5_useprev_list = '2' ;
            json.s.data.filter_6_useprev_list = '2' ;
            json.s.data.filter_7_useprev_list = '2' ;
        }
        if (json.s.id == 159599) {
            json.s.filterMainValues = {
                                        filter_3: ['6A','10A','16A','20A','25A','32A','40A','50A','60A'],
                                        filter_4: ['Тип C'],
                                     } ;
        }
        
        if (json.s) toSet.s = json.s ;
        if (!this.state.current) this.nav.setOptions({ title: json.s.short_title || json.s.title}) ;
        console.log ('AAAA',json.s.filterMainValues) ;

        toSet.filterValues = {} ;
        
        if (json.g) {
            toSet.g = json.g ;
            let filters = {} ; 
            for (let i in json.g) {
                let s = json.g[i] ;
                if (!+s.final_price) continue ;
                for (let f of toSet.s.filterFields) {
                    //console.log (i,f) ;
                    if (!s[f]) continue ;
                    let v = (''+s[f]).toLowerCase() ;
                    this.filtersValues[v] = s[f] ;
                    if (typeof filters[f] == 'undefined') filters[f] = {} ;
                    if (typeof filters[f][v] == 'undefined') filters[f][v] = 0 ;
                    filters[f][v] ++ ;
                }
            }
            for(let f in filters) if (Object.keys(filters[f]).length == 1) delete filters[f] ;
            toSet.filterValues = filters ;
            console.log (toSet.filterValues) ;
        }

        try {
            toSet.activeFilters = JSON.parse(await AsyncStorage.getItem('filters_' + this.state.id)) ;
        } catch (e) {
            toSet.activeFilters = {} ;
        }
        
        if (!toSet.activeFilters) toSet.activeFilters = {} ;
        
        this.setState(toSet) ;
      //  debugger ;

        //console.log ('-------->', this.state) ;
    }

    render () {
        
        let vendors = {} ;
        
        let subs = this.getFilteredGoods() ;
        let superTotal = Object.keys(this.state.g).length ;
        
        const onRefresh = () => {
            this.reloadData(true) ;
        }
    
        const openGood = (id) => {
            if (this.nav) this.nav.push('GoodsPage',{id:id}) ;
        }
        
        const renderItem = (item) => {
            //return false ;
            const s = item.item ;
            //debugger ;
            //-- КОСТЫЛИ
            if (s.vendor == 'Schneider Electric') s.vendor = 'SE' ;
            //console.log (s.item_id) ;
                //return false ;
            if (s.type == 'total') {
                return <Text style={{marginTop:-9,textAlign:'center',padding:2, fontSize:11,backgroundColor:'#f8f8f8'}}>ВСЕГО ТОВАРОВ &mdash; {this.subsCount} из {superTotal}</Text>
            } else if (s.type == 'filters') { 
                let fs = [] ;
                if (this.state.filtersOpen) {
                    fs = this.filterView ;
                }
                /*
                    <TouchableHighlight onPress={()=>{this.setState({filtersOpen:!this.state.filtersOpen})}} style={{alignItems: 'center', justifyContent:'center', borderColor:'#ccc', borderWidth:1, borderRadius:10, padding:10,marginTop:0,marginRight:10,width:50,height:40}}>
                        <FontAwesome5 name="heart" size={20} color="#444" />
                    </TouchableHighlight>
                */

                return  <View>
                            <View style={{width:'100%',height:60,paddingBottom:10,paddingRight:10,borderBottomWidth:1, borderBottomColor:'#eee', flexDirection:'row-reverse'}}>
                                <TouchableHighlight onPress={()=>{this.setState({filtersOpen:!this.state.filtersOpen}); console.log ('filtersOpen', this.state.filtersOpen)}} style={{alignItems: 'center', justifyContent:'center', borderColor:'#ccc', borderWidth:1, borderRadius:10, padding:10,marginTop:0,marginRight:10,width:50,height:40}}>
                                    <Octicons name="settings" size={20} color="#444" />
                                </TouchableHighlight>
                                <TouchableHighlight onPress={()=>{this.setState({sortOpen:!this.state.sortOpen})}} style={{alignItems: 'center', justifyContent:'center', borderColor:'#ccc', borderWidth:1, borderRadius:10, padding:10,marginTop:0,marginRight:10,width:50,height:40}}>
                                    <FontAwesome5 name="sort" size={20} color="#444" />
                                </TouchableHighlight>
                                <TouchableHighlight onPress={()=>{
                                    //debugger;
                                    onRefresh()
                                }} style={{alignItems: 'center', justifyContent:'center', borderColor:'#ccc', borderWidth:1, borderRadius:10, padding:10,marginTop:0,marginRight:10,width:50,height:40}}>
                                <MaterialCommunityIcons name="refresh" size={20} color="#444" />
                                </TouchableHighlight>
                            </View>
                            {fs.length ? <View style={{backgroundColor:'#f8f8f8', marginBottom:10, borderBottomWidth:1, borderBottomColor:'#eee'}}>{fs}</View> : <View></View>}
                        </View> ;
            } else if (s.type == 'shop_line' && !s.articul) {
                return <ShopLine g={item.item}/> ;
                //debugger ;
            } else if (s.item_id == 30) {
                let title = <Text onPress={() => openGood(s.id)} style={{maxHeight:50,overflow:'hidden',fontSize:12}}>{s.short_title||s.title}</Text> ;
                let articul = <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}><Text style={{fontWeight:'bold',color:'black'}}>{s.vendor}</Text> {s.articul}</Text> ;
                if (s.gv) {
                    let gv = [] ;
                    //gv.push(<Text style={{whiteSpace:'nowrap',marginRight:5}}>Автомат </Text>) ;
                    if (s.filter_3) gv.push(<Text key="filter_3" numberOfLines={1} style={{ textAlign: "left", marginRight:10}}>{s.filter_3}</Text>) ;
                    if (s.filter_2) {
                        let result = s.filter_2.match(/\([^)]*\)/g);
                        let filter_2 = s.filter_2 ;
                        if (result && result[0]) {
                            filter_2 = result[0].substr(1,result[0].length-2) ;
                        }
                        gv.push(<Text key="filter_2" numberOfLines={1} style={{ textAlign: "left", marginRight:10}}>{filter_2}</Text>) ;
                    }
                    if (s.filter_4) gv.push(<Text key="filter_4" numberOfLines={1} style={{ textAlign: "left", marginRight:10}}>{s.filter_4}</Text>) ;
                    if (gv.length >= 3) {
                        title = <View style={{flexDirection:'row',width:'100%',marginBottom:5}}>{gv}</View> ;
                    }
                }

                if (s.filter_8 && s.filter_8.indexOf(s.vendor)==-1) articul = <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}><Text style={{fontWeight:'bold',color:'black'}}>{s.vendor}</Text> {s.filter_8} {s.articul}</Text> ;
                if (s.filter_8 && s.filter_8.indexOf(s.vendor)!=-1) articul = <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}><Text style={{fontWeight:'bold',color:'black'}}>{s.filter_8}</Text> {s.articul}</Text> ;
                let img ;
                if (+s.count_photos && s.photos && s.photos[1] && s.photos[1].md5) {
                    img = <Image
                                    style={{marginRight:20,width:40,height:40,resizeMode:'contain'}}
                                    source={{uri: 'https://www.sin-el.ru/p/60x60/' + s.photos[1].md5 + '.jpg?fromExpo=true'}}
                                /> ;
                } 
                return (<View style={{overflow:'hidden',paddingTop:10,paddingLeft:10,height:60,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#eee'}} >
                        <View style={{alignContent:'center',width:40,height:40}}>{img}</View>
                        <View style={{width:dimensions.width-190,paddingLeft:10}}>
                            {articul}
                            {title}    
                        </View>
                        <View style={{justifyContent: 'center', height:40}}>
                        <Text style={{paddingRight:10,textAlign:'right',width:80,fontSize:16}}>{s.final_price} р.</Text>
                        <Text style={{paddingRight:10,textAlign:'right',width:90,marginLeft:-10,fontSize:12}}>{(+s.our_qty||0)+(+s.other_qty||0)>0 ? 'в наличии':'под заказ'}</Text>
                        </View>
                        <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:50,height:40}}>
                            <Ionicons name="md-cart" size={20} color="white"/>
                        </TouchableHighlight>
                        </View>) ;
            } else if (s.type == 'shop_line' || s.type == 'shop_sub_line' || s.type == 'shop_complex' || s.type == 'shop_link') {
            
                if (s.count_photos) {
                    return (<View style={{overflow:'hidden',paddingTop:10,paddingLeft:10,height:80,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
                            <View style={{alignContent:'center',width:60,height:60}}>
                                <Image
                                    style={{marginRight:20,width:60,height:60,resizeMode:'contain'}}
                                    source={{uri: 'https://www.sin-el.ru/p/800x/' + s.id + '_1.jpg?fromExpo=true'}}
                                />
                            </View>
                            <View style={{width:dimensions.width-210,paddingLeft:10}}>
                                <Text onPress={() => openGood(s.id)} style={{maxHeight:50,overflow:'hidden',fontSize:12}}>{s.short_title||s.title}</Text>
                                <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}>{s.vendor} {s.articul}</Text>
                            </View>
                            <View style={{justifyContent: 'center', height:40}}>
                            <Text style={{paddingRight:10,textAlign:'right',width:80,fontSize:16}}>{s.final_price} р.</Text>
                            </View>
                            <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:50,height:40}}>
                                <Ionicons name="md-cart" size={20} color="white"/>
                            </TouchableHighlight>
                            </View>) ;
                    
                }
                return (<View style={{height:60,width:'100%',flexDirection:'row',}} >
                        <View style={{width:dimensions.width-140,paddingLeft:10}}>
                            <Text onPress={() => openGood(s.id)} style={{fontSize:12}}>{s.short_title||s.title}</Text>
                            <Text onPress={() => openGood(s.id)} style={{fontSize:12,color:'gray'}}>{s.vendor} {s.articul}</Text>
                        </View>
                        <View style={{justifyContent: 'center', height:40}}>
                        <Text style={{paddingRight:10,textAlign:'right',width:80,fontSize:16}}>{s.final_price} р.</Text>
                        </View>
                        <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:50,height:40}}>
                            <Ionicons name="md-cart" size={20} color="white"/>
                        </TouchableHighlight>
                        </View>) ;
            }
            return false ;
        }
        return (
            <SafeAreaView style={styles.container}>
                <FlatList
                        contentContainerStyle={{ flexGrow: 1 }}
                        data={subs}
                        style={{paddingTop:10}}
                        onRefresh={onRefresh}
                        refreshing={this.state.loading}
                        renderItem={renderItem}
                        keyExtractor={item => item.id}
                      />
            
            </SafeAreaView>
        ) ;
    }
}
import * as SQLite from 'expo-sqlite';
const db = SQLite.openDatabase('app.db');
import { Alert } from 'react-native';

import AsyncStorage from '@react-native-async-storage/async-storage';


class Database {
    db;
    needUpdate = {} ;
    constructor(db){
        this.db =db;
    }
    executeSql = (sql,params=[])=>new Promise((resolve , reject)=>{
        this.db.transaction((trans)=>{
            trans.executeSql(sql,params,(db,results)=>{

                resolve(results);
            },
            (error)=>{
                reject(error);
            });
        });
    });

    async getBulk (name) {
        let json = false ;
        if (!$name) return { error: 'getBulk(name)' } ;
        
        await DB.executeSql('CREATE TABLE IF NOT EXISTS bulks (name VARCHAR(50) PRIMARY KEY NOT NULL, data TEXT)', []);
        let z = await this.executeSql('SELECT data FROM `bulks` WHERE name=?', [name]) ;
        if (z.rows.length == 1) {
            json = JSON.parse(z.rows.item(0).data) ;
        }
        if (!json || (Object(json.s).length==0)) {
            json = await updateBulks(true) ;
        }
        if (json) {
            return json ;
        }
        return { 'error' : 'bulk not found' } ;
    }
    
    async getSection (section_id, force) {
        let json = false ;
        console.log ('getSection', section_id) ;
        await this.executeSql('CREATE TABLE IF NOT EXISTS sgd (id INTEGER PRIMARY KEY NOT NULL, data TEXT)', []);

//        if (section_id == 33054) force = true ; ;

        if (!force) {
            let z = await this.executeSql('SELECT data FROM `sgd` WHERE id=?', [section_id]) ;
            if (z.rows.length == 1) {
                json = JSON.parse(z.rows.item(0).data) ;
            }
            
            
            if (json&&!json.error) { //Ищем обновленные товары
            
                let ts_g = await AsyncStorage.getItem('ts_g_' + section_id) ; //Берем таймспамп последнего обновления товаров
                let ts_s = await AsyncStorage.getItem('ts_s_' + section_id) ; //Берем таймспамп последнего обновления наличия
                if (!json||!json['g']||!Object.keys(json['g']).length) { //Если массив товаров пуст, то используем таймспамп как 0
                    ts_g=0;
                    ts_s=0;
                }
                let response = await fetch(
                    'https://www.sin-el.ru/api/getSection?section_id='+section_id+'&ts_g='+ts_g+'&ts_s='+ts_s
                );
                let json2 = await response.json();
                if (!json2.error) {
                    if (json2['g']) {
                        for(var id in json2['g']) { //перебираем полученный массив товаров, и дополняем тот что был
                            if (json['g']) {
                                json['g'][id]=json2['g'][id];
                            }
                        }
                    }
                    if (json2['ss']) {
                        for(var id in json2['ss']) { //перебираем полученный массив остатков, и заполняем наличием товары
                            if (json['g']&&json['g'][id]) { 
                                json['g'][id]['our_qty']=json2['ss'][id][0]; //для уменьшения кол-ва переданной инфы входящий массив это [id=>[our_qty,other_qty]]
                                json['g'][id]['other_qty']=json2['ss'][id][1];
                            }
                        }
                    }
                    if (json2['ts_g']) json['ts_g']=json2['ts_g']; //заменяем таймспамп последнего обновления товаров
                    if (json2['ts_s']) json['ts_s']=json2['ts_s']; //заменяем таймспамп последнего обновления наличия
                }
                json = await this.saveSection(section_id, json); //сохраняем в db
            }
        }
        
        if (!json || json.error) {
            json = await this.updateSection(section_id) ;
        }
        console.log (json) ;
        if (json) {
            return json ;
        }
        return { 'error' : 'section_id not found' } ;
    }
    
    
    
    async updateSection (section_id) {
        let response = await fetch(
            'https://www.sin-el.ru/api/getSection?section_id='+section_id
        );
        let json = await response.json();
        if (!json || json.error) {
            console.log ('updateSection',json) ;
            if (json['ss']) {
                for(var id in json['ss']) { //перебираем полученный массив остатков, и заполняем наличием
                    if (json['g']&&json['g'][id]) { 
                        json['g'][id]['our_qty']=json2['ss'][id][0]; //для уменьшения кол-ва переданной инфы входящий массив это [id=>[our_qty,other_qty]]
                        json['g'][id]['other_qty']=json2['ss'][id][1];
                    }
                }
            }
        }
        
        return await this.saveSection(section_id, json); //сохраняем в db
    }
    
    async saveSection (section_id, json) {
        if (!json.error) {
            if (json.ss) delete json.ss; //ss уже был отработан до, он не нужен
            
            await this.executeSql('DELETE FROM sgd WHERE id=?', [section_id]);
            await this.executeSql('INSERT INTO sgd (id, data) VALUES (?, ?)', [section_id, JSON.stringify(json)]);
            await AsyncStorage.setItem('ts_g_' + section_id, ''+json.ts_g) ;
            await AsyncStorage.setItem('ts_s_' + section_id, ''+json.ts_s) ;
        }
        return json ;
    }

    async updateBulks (force) {
        let response = await fetch(
            'https://www.sin-el.ru/api/getSections'
        );
        let json = await response.json() ;
        if (!json.error) {
            await this.executeSql('DELETE FROM bulks WHERE name=?', ['s']);
            await this.executeSql('INSERT INTO bulks (name, data) VALUES (?, ?)', ['s', JSON.stringify(json)]);
            this.needUpdate.bulks = true ;
        }
        return json ;
    } ;
    
    async updateAll (force) {
        updateBulks(true)
    }

}

export default new Database(db) ;
import { Dimensions, Platform, StyleSheet } from 'react-native';

const dim = Dimensions.get('window');
function isIphoneX() {
  return ( Platform.OS === 'ios' && (isIPhoneXSize(dim) || isIPhoneXrSize(dim)) );
}
function isIPhoneXSize(dim) { return dim.height == 812 || dim.width == 812; }
function isIPhoneXrSize(dim) { return dim.height == 896 || dim.width == 896; }

const HEADER_SIZE = isIphoneX() ? 40 : 0;

export default StyleSheet.create({
    filterButton: {
        padding:10, 
        paddingLeft:15,
        paddingRight:15,
        marginLeft: 10,
        marginBottom: 10,
        borderRadius:10,
        backgroundColor: '#eee',
    },
    filterButtonActive: {
        padding:10, 
        paddingLeft:15,
        paddingRight:15,
        marginLeft: 10,
        marginBottom: 10,
        borderRadius:10,
        backgroundColor: '#4a4',
        color:'white',
    },
  stretch: {
    width: 50,
    height: 50,
    resizeMode: 'stretch',
  },
  container: {
    paddingTop: HEADER_SIZE,
    flex: 1,
    fontSize:30,
    backgroundColor: '#fff',
//    alignItems: 'center',
//    justifyContent: 'center',
  },
  menuLine : {justifyContent: 'center',padding:20,backgroundColor:'white', borderBottomWidth:1, borderBottomColor:'#f0f0f0'},
  menuLineText :{width:dim.width-100, color:'black', fontSize:16},
  menuLineDisabled : {justifyContent: 'center',padding:20,backgroundColor:'#eee', borderBottomWidth:1, borderBottomColor:'#f0f0f0'},
  menuLineDisabledText :{width:dim.width-100,color:'#aaa',fontSize:16},
  
});

import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { TouchableOpacity, FlatList, SafeAreaView, TextInput, TouchableHighlight, Dimensions, ScrollView, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';

//import AsyncStorage from '@react-native-async-storage/async-storage';

//import Icon from 'react-native-vector-icons/MaterialIcons';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Navigation, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';


import styles from '../styles/common.js' ;

const dimensions = Dimensions.get('window');

import DB from '../data/db.js' ;

export default class SectionsList extends React.Component {
    
    state = {
        loading: true,
        current: 0,
        s: {},
    }

    constructor (props) {
        super(props) ;
        if (props.route && props.route.params && props.route.params.id) this.state.current = props.route.params.id ;
        if (props.navigation) this.nav = props.navigation ;
        this.handleScroll = this.handleScroll.bind(this);
        this.reloadData = this.reloadData.bind(this);
    }


    async componentDidMount() {
        await this.reloadData() ;
        //console.log ('componentDidMount',this.state.current) ;
        //console.log ('componentDidMount',this.state.current) ;
        if (!this.state.current) this.nav.setOptions({ title: 'Каталог товаров'}) ;
        else if (this.state.s[this.state.current]) this.nav.setOptions({ title: this.state.s[this.state.current].short_title || this.state.s[this.state.current].title}) ;
    }
    
    async reloadData(force) {
        this.state.loading = true ;
        let json = false ;

     //   console.log ('SectionsList.reloadData', DB.needUpdate) ;

        await DB.executeSql('CREATE TABLE IF NOT EXISTS bulks (name VARCHAR(50) PRIMARY KEY NOT NULL, data TEXT)', []);

        if (!force) {
            let z = await DB.executeSql('SELECT data FROM `bulks` WHERE name=?', ['s']) ;
            if (z.rows.length == 1) {
                json = JSON.parse(z.rows.item(0).data) ;
            }
        }
        if (!json || (json.s && Object(json.s).length==0)) {
            //console.log ('loading') ;
            let response = await fetch(
                'https://www.sin-el.ru/api/getSections'
            );
            json = await response.json();
            await DB.executeSql('DELETE FROM bulks WHERE name=?', ['s']);
            await DB.executeSql('INSERT INTO bulks (name, data) VALUES (?, ?)', ['s', JSON.stringify(json)]);
        }
        
        let toSet = {loading:false} ;
        if (json.s) toSet.s = json.s ;
        this.setState(toSet) ;
        //console.log ('SectionsList',Object.keys(this.state.s).length) ;
    }

    handleScroll(event: Object) {
        if (event.nativeEvent.contentOffset.y < 100) {
            if (this && this.state && !this.state.loading) this.reloadData() ;
        }
        console.log(event.nativeEvent.contentOffset.y);
    }

    render () {
        //console.log ('render SectionsList', this.state) ;
        let subs = [] ; let dupes = {} ;
        if (this.state.s) {
            for (let i in this.state.s) {
                let s = this.state.s[i] ;
                if (!s || !+s.id||0) continue ;
                //console.log ('s',s.parent_id, this.state.current) ;
                if ((+s.__pid||0) != (+this.state.current||0)) continue ;
                let id = +s.id||0 ;
                if (dupes[id]) continue ;
                if (!dupes[id]) dupes[id] = 1 ; 
                
                
                subs.push(s) ;
            }
            //if (Object.keys(dupes).length>0) console.log('dupes', dupes)
        }
        //console.log ('suk',subs) ;

        const openSection = (id) => {
            //this.reloadData(true) ;
           // console.log ('GoodsSections',{id:id})
            if (this.nav) this.nav.push('GoodsSections',{id:id}) ;
        }        
        const openGoods = (id) => {
            //this.reloadData(true) ;
          //  console.log ('GoodsList',{id:id})
            if (this.nav) this.nav.push('GoodsList',{id:id}) ;
        }

        const onRefresh = () => {
            //this.reloadData(true) ;
            //debugger 
            //console.log ('onRefresh')
            if (this && this.state && !this.state.loading) this.reloadData(true) ;
        }
        
        
        
        const renderItem = (item) => {
            
            let s = item.item ;

            let photoSize = (dimensions.width== 320) ? 70 : 110 ;
            let fontSize = (dimensions.width== 320) ? 16 : 18 ;
            let lineHeight = (dimensions.width== 320) ? 16 : 18 ;
//            console.log ('fontSize',fontSize) ;

            let title = [<Text key="FUCK">{s.short_title||s.title}</Text>] ;

            if (s.data && s.data.app_section_title && s.data.app_section_descr) {
                title = [
                    <View key={'sect_'+s.id}>
                        <Text style={{color:'#444',fontSize:fontSize,lineHeight:lineHeight,paddingRight:10,fontWeight:'bold',width:'100%'}}>{s.data.app_section_title}</Text>
                        <Text style={{marginTop:2,fontSize:12,lineHeight:14,paddingRight:10,width:'100%'}}>{s.data.app_section_descr}</Text>
                    </View>
                    ] ;
                
            }

            if (s.data && s.data.app_section_title && !s.data.app_section_descr) {
                title = [
                    <View key={'sect_'+s.id}><Text style={{fontSize:fontSize,fontWeight:'bold',width:'100%'}}>{s.data.app_section_title}</Text></View>
                    ] ;
                
            }

            let max_img_width = 0 ; //(dimensions.width == 320) ? 30 : 90 ;
            
            if (s.md5) {
                return (<TouchableOpacity onPress={s.hasSections ? () => openSection(s.__id) : () => openGoods(s.id) } key={'section_'+s.__id}  
                            style={{justifyContent:'center',height:photoSize+20,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
                
                        <View style={{justifyContent:'center',width:photoSize,marginRight:30,height:photoSize+20}}>
                            <Image
                                style={{marginLeft:10,marginRight:10,width:photoSize,height:photoSize,resizeMode:'contain'}}
                                source={{uri: 'https://www.sin-el.ru/p/220x/' + s.md5 + '.jpg?fromExpo=true'}}
                            />
                        </View>
                        <View style={{justifyContent:'center',width:dimensions.width-photoSize-40}}>
                            <Text style={{fontSize:20}} onPress={s.hasSections ? () => openSection(s.__id) : () => openGoods(s.id) }>{title}</Text>
                        </View>
                    </TouchableOpacity>) ;
            } else {
                return (<TouchableOpacity onPress={s.hasSections ? () => openSection(s.__id) : () => openGoods(s.id) } key={'section_'+s.__id}  style={{height:60,width:'100%',flexDirection:'row',borderBottomWidth:1,borderBottomColor:'#EEE'}} >
            
                        <View style={{justifyContent:'center',width:dimensions.width,paddingLeft:10}}>
                            <Text  style={{fontSize:20}}>{s.short_title||s.title}</Text>
                        </View>
                    </TouchableOpacity>) ;
            }
        }
        
        return (
            <SafeAreaView style={styles.container}>
                <FlatList
                        data={subs}
                        renderItem={renderItem}
                        onRefresh={onRefresh}
                        refreshing={this.state.loading}
                        keyExtractor={item => item.id}
                      />
            </SafeAreaView>
            
        ) ;
        /*<Text style={{textAlign:'center',width:'100%',fontSize:30,marginBottom:20}}>{this.state.good.title}</Text>           */
    }
}
import React, { useState } from 'react';
import { AsyncStorage, FlatList, SafeAreaView, TextInput, TouchableHighlight, Dimensions, ScrollView, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';
import DB from '../data/db.js' ;
//import DeviceInfo from 'react-native-device-info';

//console.log (DeviceInfo) ;

import { AntDesign , Entypo, MaterialCommunityIcons, Ionicons, FontAwesome5 } from '@expo/vector-icons';

import styles from '../styles/common.js' ;

//import NotificationPopup from 'react-native-push-notification-popup';
//import PopupX from 'react-native-pop' ;
const dimensions = Dimensions.get('window');

const UselessTextInput = (props) => {
  //const [value, onChangeText] = React.useState('');
    console.log ('UselessTextInput',props) ;
    let value = '' ;
    if (props && props.value) value = props.value ;
  return (
    <TextInput
      style={{ width:'100%', height: 40, backgroundColor: '#e8e8e8', paddingLeft:10, paddingRight: 10, marginBottom: 10 }}
      value={value}
    />
  );
}


const renderCustomPopup = ({ appIconSource, appTitle, timeText, title, body }) => (
  <View style={{shadowColor:'black', shadowOpacity:1, shadowOffset:{weight:15,height:15}, backgroundColor:'rgba(64,128,64,0.9)',marginTop:-5,padding:10,borderColor:'#eee',borderWidth:1,borderRadius:10,overflow:'hidden',alignItems:'center'}}>
    <Text style={{color:'#fff'}}>{body}</Text>
  </View>
);

export default class My extends React.Component {
    state = {} ;

    constructor (props) {
        super(props) ;
        if (props.navigation) this.nav = props.navigation ;
        this.state.city = 'Москва'
        //this.state.name = 'Валентин'
        this.state.phone = '+79057284455'
        this.state.email = 'vn@77mail.ru' 
        this.nav.setOptions({ title: 'Мой кабинет'}) ;

    }
    
    componentDidMount () {
    //    this.state.uid = DeviceInfo.getUniqueId() ;
    }

    render() {
        

        let uid = <View style={{flexDirection:'row', backgroundColor:'white',  padding: 20, backgroundColor:'#f8f8f8'}}>
                    <FontAwesome5 name="user" size={24} color="black" />
                    <Text style={{marginLeft:10, fontSize:16, padding:5, color:'#888', fontWeight:'bold'}}> {this.state.phone} {this.state.uid}</Text>
                </View>
        
        return (
            <ScrollView>
                <TouchableHighlight
                    style={styles.menuLine}
                    
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                    <View style={{width:40}}><FontAwesome5 name="sms" size={24} color="#666" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Авторизуйтесь через СМС чтобы получить доступ к разделам.</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>


                <TouchableHighlight
                    style={styles.menuLineDisabled}
                    
                    onPress={async () => {
                        //this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'
                    }}>
                    <View style={{width:40}}><FontAwesome5 name="address-card" size={24} color="#666" /></View>
                        <View><Text style={styles.menuLineDisabledText}>Личная информация</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>


                <TouchableHighlight
                    style={styles.menuLineDisabled}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><Entypo name="address" size={24} color="#666" /></View>
                        <View><Text style={styles.menuLineDisabledText}>Адреса доставок</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>

                <TouchableHighlight
                    style={styles.menuLineDisabled}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><FontAwesome5 name="archive" size={24} color="#666" /></View>
                        <View><Text style={styles.menuLineDisabledText}>Архив заказов</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>

                <View style={{height: 20, backgroundColor:'#f8f8f8'}}></View>

                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><MaterialCommunityIcons name="map-marker" size={24} color="#666" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Как к нам добраться</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>

                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><FontAwesome5 name="whatsapp" size={24} color="#666" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Напишите нам в WhatsApp</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>                    
                
                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><FontAwesome5 name="telegram" size={24} color="#666" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Напишите нам в Telegram</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>
                
                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><FontAwesome5 name="phone" size={24} color="#666" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Позвоните нам</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="#666" /></View>
                    </View>
                </TouchableHighlight>

                <View style={{height: 20, backgroundColor:'#f8f8f8'}}></View>

                {false ? 
                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        this.nav.push('Profile') ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><MaterialCommunityIcons name="logout-variant" size={24} color="black" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Выйти из аккаунта</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="black" /></View>
                    </View>
                </TouchableHighlight>
                : <></> }

                <View style={{height: 20, backgroundColor:'#f8f8f8'}}></View>

                <TouchableHighlight
                    style={styles.menuLine}
                    onPress={async () => {
                        let bulks = await DB.updateBulks(true) ;
                        /*this.popup.show({
                            onPress: function() {console.log('Pressed')},
                         //   appIconSource: require('/assets/images/icon.png'),
                            //appTitle: 'СИНЭЛ-ПРО',
                            //timeText: 'Сейчас',
                            body: 'Обновление данных завершено',
                            slideOutTime: 1000
                          });*/
                        console.log ('refresh bulks', bulks) ;
                    }}
                  >
                    <View style={{alignItems: 'stretch', flexDirection:'row', width:'100%'}}>
                        <View style={{width:40}}><MaterialCommunityIcons name="refresh" size={24} color="black" /></View>
                        <View><Text style={{width:dimensions.width-100,color:'black',fontSize:16}}>Обновить базу товаров</Text></View>
                        <View><MaterialCommunityIcons name="chevron-right" size={24} color="black" /></View>
                    </View>
                </TouchableHighlight>

            </ScrollView>
    );
    /*                <NotificationPopup ref={ref => this.popup = ref} renderPopupContent={renderCustomPopup} />
*/
  }
}

import React, { useState } from 'react';
import { AsyncStorage, FlatList, SafeAreaView, TextInput, TouchableHighlight, Dimensions, ScrollView, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';
import DB from '../data/db.js' ;

import NotificationPopup from 'react-native-push-notification-popup';
//import PopupX from 'react-native-pop' ;

const UselessTextInput = (props) => {
  //const [value, onChangeText] = React.useState('');
    console.log ('UselessTextInput',props) ;
    let value = '' ;
    if (props && props.value) value = props.value ;
  return (
    <TextInput
      style={{ width:'100%', height: 40, backgroundColor: '#e8e8e8', paddingLeft:10, paddingRight: 10, marginBottom: 10 }}
      value={value}
    />
  );
}

const renderCustomPopup = ({ appIconSource, appTitle, timeText, title, body }) => (
  <View style={{shadowColor:'black', shadowOpacity:1, shadowOffset:{weight:15,height:15}, backgroundColor:'rgba(64,128,64,0.9)',marginTop:-5,padding:10,borderColor:'#eee',borderWidth:1,borderRadius:10,overflow:'hidden',alignItems:'center'}}>
    <Text style={{color:'#fff'}}>{body}</Text>
  </View>
);

export default class ProfileScreen extends React.Component {
    state = {} ;

    constructor (props) {
        super(props) ;
        if (props.navigation) this.nav = props.navigation ;
        this.state.city = 'Москва'
        this.state.name = 'Валентин'
        this.state.phone = '+79057284455'
        this.state.email = 'vn@77mail.ru' 
        this.nav.setOptions({ title: 'Моя информация'}) ;
    }

    render() {
        return (
            <>
                <>
                    <Text style={{textAlign:'center',fontSize:18, fontWeight:'bold',  padding:10, paddingTop:10, backgroundColor:'#fff', borderBottomWidth: 1, borderBottomColor: '#ddd'}}>Моя информация</Text>
                    <View style={{ flex: 1, padding:10,backgroundColor: '#f8f8f8'  }}>
                        <Text style={{paddingBottom:5}}>Населённый пункт</Text>
                        <UselessTextInput value={this.state.city}/>
                        <Text style={{paddingBottom:5}}>Имя</Text>
                        <UselessTextInput value={this.state.name}/>
                        <Text style={{paddingBottom:5}}>Телефон</Text>
                        <UselessTextInput value={this.state.phone}/>
                        <Text style={{paddingBottom:5}}>Email</Text>
                        <UselessTextInput value={this.state.email}/>
                        <View style={{marginTop:20}}>
                        <TouchableHighlight
                            title=""
                            onPress={() => {
                                console.log ('saving', this.state.city, this.state.name, this.state.phone, this.state.email) ;
                            }}
                        ><Text>Сохранить</Text></TouchableHighlight>
        
                        <TouchableHighlight
                            title="Обновить"
                            style={{alignItems: 'center', justifyContent: 'center',marginTop:20,paddingTop:10,paddingButton:10,paddindLeft:10,paddingRight:10,paddingBottom:10, borderRadius:5,backgroundColor:'#4a4'}}
                            onPress={async () => {
                                let bulks = await DB.updateBulks(true) ;
                                this.popup.show({
                                    onPress: function() {console.log('Pressed')},
                                 //   appIconSource: require('/assets/images/icon.png'),
                                    //appTitle: 'СИНЭЛ-ПРО',
                                    //timeText: 'Сейчас',
                                    body: 'Обновление данных завершено',
                                    slideOutTime: 1000
                                  });
                                console.log ('refresh bulks', bulks) ;
                            }}
                          ><Text style={{color:'#fff'}}>Обновить</Text></TouchableHighlight>
                        </View>
                    </View>
                </>
                <NotificationPopup ref={ref => this.popup = ref} renderPopupContent={renderCustomPopup} />
            </>
    );
  }
}

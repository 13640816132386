import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { AsyncStorage, FlatList, SafeAreaView, TextInput, TouchableHighlight, Dimensions, ScrollView, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';

import HTML from "react-native-render-html";

//import AsyncStorage from '@react-native-async-storage/async-storage';

//import Icon from 'react-native-vector-icons/MaterialIcons';
import { MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Navigation, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import * as SQLite from 'expo-sqlite';

import styles from '../styles/common.js' ;

function isIphoneX() {
  const dim = Dimensions.get('window');
  return ( Platform.OS === 'ios' && (isIPhoneXSize(dim) || isIPhoneXrSize(dim)) );
}
function isIPhoneXSize(dim) { return dim.height == 812 || dim.width == 812; }
function isIPhoneXrSize(dim) { return dim.height == 896 || dim.width == 896; }

const HEADER_SIZE = isIphoneX() ? 60 : 20;

const dimensions = Dimensions.get('window');

const db = SQLite.openDatabase('app.db');

class Database{
    db;
    constructor(db){
        this.db =db;
    }
    executeSql = (sql,params=[])=>new Promise((resolve , reject)=>{
        this.db.transaction((trans)=>{
            trans.executeSql(sql,params,(db,results)=>{

                resolve(results);
            },
            (error)=>{
                reject(error);
            });
        });
    });

}

const DB = new Database(db) ;

export default class GoodsPage extends React.Component {
    state = {
        loading: true,
        id:390246,
        good: {}
    }
    
    constructor (props) {
        super(props) ;
        if (props.route && props.route.params && props.route.params.id) {
            this.state.id = props.route.params.id ;
        }
        if (props.navigation) this.nav = props.navigation ;
        //console.log ('GoodsPage.constructor', this.state.id) ;
    }    
    
    async componentDidMount() {
      let response = await fetch(
        'https://www.sin-el.ru/api/getGood?id=' + this.state.id
      );
      let json = await response.json();
      if (json.count_photos) json.imgUrl = 'https://www.sin-el.ru/p/800x/' + json.id + '_%num%.jpg' ;
      this.setState({good:json,loading:false}) ;
    }
    
    render () {
        if (this.state.loading) {
            return(<></>) ;
        }
        //console.log (Platform) ;
        const photos = [] ;
        const good = this.state.good ;
        
        //console.log (good) ;
        for (let i = 1; typeof good.photos[i] != 'undefined' ; i++) {
            let p = good.photos[i] ;
            if (+p.hide) continue ;
            //console.log (i,p) ;
            let width = Math.min(dimensions.width-50, p.width / p.height * 300 ) ;
            photos.push(<Image
                    key={'photo_'+i} 
                    /*style={{maxWidth:dimensions.width-10, width:'100%', height: '50%', maxHeight:300, resizeMode: 'contain'}}*/
                    style={{marginRight:20,width:width,height:300,resizeMode:'contain'}}
                    source={{uri: 'https://www.sin-el.ru/p/800x/' + good.id + '_'+i+'.jpg?fromExpo=true'}}
                  ></Image>) ;
        }
        let subs = [] ;
        if (good.subs) {
            for (let i in good.subs) {
                let s = good.subs[i] ;
                //console.log ('aaaaaaaaa', 'sub_title_'+s.id,'sub_final_price_'+s.id) ;
                subs.push(<View key={'sub_'+s.id} style={{height:50,width:'100%',flexDirection:'row',}}>
                    <View style={{width:dimensions.width-140,paddingLeft:10}}>
                        <Text style={{fontSize:16}}>{s.short_title||s.title}</Text>
                        <Text style={{fontSize:12,color:'gray'}}>{s.vendor} {s.articul}</Text>
                    </View>
                    <View style={{justifyContent: 'center', height:40}}>
                    <Text style={{paddingRight:10,textAlign:'right',width:80,fontSize:16}}>{s.final_price} р.</Text>
                    </View>
                    <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:50,height:40}}>
                        <Ionicons name="md-cart" size={20} color="white"/>
                    </TouchableHighlight>
                    </View>) ;
                
            }
        } else {
            let s = good; 
            //console.log (s) ;
            subs.push(<View key={'sub_'+s.id} style={{flex:1,alignItems: 'center', height:50,width:dimensions.width,flexDirection:'row'}}>
                <View style={{justifyContent: 'center', width:140+(dimensions.width-240)/2, height:50 }}>
                <Text style={{paddingRight:10,textAlign:'right',width:'100%',fontSize:20}}>Цена {s.final_price} р.</Text>
                </View>
                <TouchableHighlight onPress={()=>{}} style={{alignItems: 'center', justifyContent:'center', backgroundColor:'#4a4', borderRadius:10, padding:10,marginTop:0,width:100,height:40}}>
                    <Ionicons name="md-cart" size={20} color="white"/>
                </TouchableHighlight>
                </View>) ;
           // console.log ('subs',subs) ;
        }
        let texts = [] ;
        if (good.side_text) texts.push(<View key="side_text" style={{padding:10}}><HTML html={good.side_text} contentWidth={'100%'} /></View>) ;
        if (good.tab_1) texts.push(<View key="tab_1" style={{padding:10}}><HTML html={good.tab_1} contentWidth={'100%'} /></View>) ;
        if (good.tab_2) texts.push(<View key="tab_2" style={{padding:10}}><HTML html={good.tab_2} contentWidth={'100%'} /></View>) ;
        if (good.body_text) texts.push(<View key="bottom_text" style={{padding:10}}><HTML html={good.body_text} contentWidth={'100%'} /></View>) ;
        
        console.log (good) ;
        return (
            <ScrollView style={styles.container}>
            <ScrollView 
                    style={{width:'100%',height:320,paddingTop:0,paddingLeft:20,marginBottom:10}} 
                     horizontal={true}
                >
            {photos}
             </ScrollView>
            <Text style={{textAlign:'center',width:'100%',fontSize:30,marginBottom:20}}>{this.state.good.title}</Text>
            {subs}
            {texts}
            <View style={{height:100}}></View>
            </ScrollView>
        ) ;
    }
}
import { StatusBar } from 'expo-status-bar';
import React, { useState } from 'react';
import { Linking, Dimensions, StyleSheet, Text, Image, View, Button, Platform  } from 'react-native';
import { getFocusedRouteNameFromRoute } from '@react-navigation/native';
import NetInfo from "@react-native-community/netinfo";

import AsyncStorage from '@react-native-async-storage/async-storage';
const PERSISTENCE_KEY = 'NAVIGATION_STATE';

//import AsyncStorage from '@react-native-async-storage/async-storage';

//import Icon from 'react-native-vector-icons/MaterialIcons';
import { AntDesign , MaterialCommunityIcons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
//import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { Navigation, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import * as SQLite from 'expo-sqlite';

import ProfileScreen from './components/profile.js' ;
import My from './components/my.js' ;
import Scanner from './components/scanner.js' ;
import SectionsList from './components/sectionsList.js' ;
import GoodsList from './components/goodsList.js' ;
import GoodsPage from './components/goodsPage.js' ;
import styles from './styles/common.js' ;


const dimensions = Dimensions.get('window');


function HomeScreen({ navigation }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>Home Screen</Text>
      <Button
        title="Go to Details"
        onPress={() => navigation.navigate('Details')}
      />
    </View>
  );
}

function DebugGoodsScreen(props) {
  return (
      <Stack.Navigator>
        <Stack.Screen name="GoodsSections" component={SectionsList} options={{title:''}} />
        <Stack.Screen name="GoodsList" component={GoodsList} options={{title:''}} />
        <Stack.Screen name="GoodsPage" component={GoodsPage} options={{title:''}} />
      </Stack.Navigator>
  );
}

function GoodsScreen(props) {
  return (
      <Stack.Navigator>
        <Stack.Screen name="GoodsSections" component={SectionsList} options={{title:''}} />
        <Stack.Screen name="GoodsList" component={GoodsList} options={{title:''}} />
        <Stack.Screen name="GoodsPage" component={GoodsPage} options={{title:''}} />
      </Stack.Navigator>
  );
}

function PersonalScreen(props) {
  return (
      <Stack.Navigator>
        <Stack.Screen name="My" component={My} options={{title:''}} />
        <Stack.Screen name="Profile" component={ProfileScreen} options={{title:''}} />
      </Stack.Navigator>
  );
}

const Tab = createBottomTabNavigator();


function MyTabs() {
  return (
    <Tab.Navigator bars
            screenOptions={({ route }) => ({
              tabBarIcon: ({ focused, color, size }) => {
                let iconName;
                let routeName = route.name ; //getFocusedRouteNameFromRoute(route) ;
                if (routeName === 'GoodsScreen') {
                    return <Ionicons name="ios-home" size={size} color={color} />;
                  iconName = focused ? 'storefront' : 'storefront-outline';
                  return <MaterialCommunityIcons name="storefront" size={size} color={color} />;
                  //return <MaterialCommunityIcons name={iconName} size={size} color={color} />;
                } else if (routeName === 'GoodsPage') {
                    return <Ionicons name="ios-apps" size={size} color={color} />;
                } else if (routeName === 'SearchScreen') {
                    return <Ionicons name="ios-search" size={size} color={color} />;
                  iconName = focused ? 'ios-list' : 'ios-list-outline-outline';
                } else if (routeName === 'Basket') {
                    return <Ionicons name="ios-cart" size={size} color={color} />;
                  iconName = focused ? 'cart' : 'cart-outline';
                } else if (routeName === 'Favorites') {
                    return <Ionicons name="ios-heart" size={size} color={color} />;
                  iconName = focused ? 'ios-heart' : 'ios-heart-outline';
                } else if (routeName === 'Profile') {
                    
                return <FontAwesome5 name="bars" size={size} color={color} />;
                  iconName = focused ? 'ios-list-box' : 'ios-list';
                }
    
                // You can return any component that you like here!
                return <Ionicons name={iconName} size={size} color={color} />;
              },
            })}
            tabBarOptions={{
              activeTintColor: 'tomato',
              inactiveTintColor: 'gray',
            }}
        >
      <Tab.Screen name="GoodsScreen" component={DebugGoodsScreen}  options={{title:'Каталог'}}/>
      <Tab.Screen name="SearchScreen" component={Scanner} options={{title:'Поиск'}} />
      <Tab.Screen name="Basket" component={HomeScreen}  options={{title:'Корзина'}}/>
      <Tab.Screen name="Favorites" component={HomeScreen}  options={{title:'Избранное'}}/>
      <Tab.Screen name="Profile" component={PersonalScreen}  options={{title:'Меню'}}/>
    </Tab.Navigator>
  );
}

const Stack = createStackNavigator();

function App() {
    const [isReady, setIsReady] = React.useState(false);
    const [isOnline, setOnline] = React.useState();


    const unsubscribe = NetInfo.addEventListener(state => {
        if (state.isConnected != isOnline) setOnline(state.isConnected) ;
    });

    const [initialState, setInitialState] = React.useState();
    React.useEffect(() => {
        const restoreState = async () => {
          try {
            const initialUrl = await Linking.getInitialURL();
    
            if (false || (Platform.OS !== 'web' && initialUrl == null)) {
              // Only restore state if there's no deep link and we're not on web
              const savedStateString = await AsyncStorage.getItem(PERSISTENCE_KEY);
              const state = savedStateString ? JSON.parse(savedStateString) : undefined;
              //const state = {} ;
    
              if (state !== undefined) {
                setInitialState(state);
              }
            }
          } finally {
            setIsReady(true);
          }
        };
    
        if (!isReady) {
          restoreState();
        }
    }, [isReady]);
    
    if (!isReady) {
        return null;
    }
    
    
  let mainNav = <NavigationContainer
            initialState={initialState}
              onStateChange={(state) => {
                AsyncStorage.setItem(PERSISTENCE_KEY, JSON.stringify(state)) 
                console.log ('saving', PERSISTENCE_KEY, state) ;
              }}
            >
        <MyTabs />
    </NavigationContainer> 
    
    let offLine ;
  if (!isOnline) {
      offLine = <View style={{position:'absolute',top:0,left:0,right:0, width:'100%',height:20, alignItems:'center',zIndex:100}}>
                    <Text style={{backgroundColor:'rgba(0,0,0,0.2)',color:'#444', fontSize:12,paddingTop:1,paddingBottom:3,paddingLeft:10,paddingRight:10,borderBottomLeftRadius:10,borderBottomRightRadius:10}}>нет связи - режим оффлайн</Text>
                </View>
  }
  return (
    <>  
        {offLine}
        {mainNav}
    </>
  );
}



export default App;


/*export default function App() {
  return (
    <View style={{width:'100%',height:'100%'}}>
      <HomeScreen/>
    </View>
  );
}*/

